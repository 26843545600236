import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
`

const Inner = styled.div`
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const VideoContainer = styled.div`
  max-width: 90vw;
  @media (min-width: 1024px) {
    max-width: 60vw;
  }

  margin: 0 auto;
  background: white;
  padding: 20px;
  box-shadow: 1px 1px 10px black;
`

function VideoModal({
  title,
  description,
  video,
  onClose,
  closeOnOverlayClick = true,
  footer,
}) {
  return (
    <Container
      onClick={(e) => {
        e.stopPropagation()
        if (closeOnOverlayClick && onClose) {
          onClose()
        }
      }}
    >
      <Inner>
        <VideoContainer
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className="flex justify-between pb-2 ">
            <h3 className="text-2xl md:text-4xl leading-none text-charcoal font-sans">
              {title}{' '}
            </h3>
            <span className="align-end text-4xl -mt-8 py-2 ">
              <a
                className="no-underline text-black"
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  if (onClose) {
                    onClose()
                  }
                }}
              >
                &times;
              </a>
            </span>
          </div>
          <div
            className="pb-2 leading-thig"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <video src={video} className="w-full" controls playsInline autoPlay />
          {footer ? (
            <p className="pt-2">
              <strong>{footer}</strong>
            </p>
          ) : null}
          <div></div>
        </VideoContainer>
      </Inner>
    </Container>
  )
}

export default VideoModal
