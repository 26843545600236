import React, { useState } from 'react'
import classnames from 'classnames'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import VideoModal from '../VideoModal'
import PlayIconImage from '../../../../../images/media-kit/PlayIconImage.png'

const PlayIcon = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`

const Poster = styled.img`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const ImageContainer = styled.div`
  padding-bottom: 56.2%;
  position: relative;
`
const ContainerDescription = styled.div`
  ${tw`
    text-base
    lg:leading-relaxed
    leading-firm
    font-charcoal
    pb-4
    lg:pb-5
    px-0
    xl:pt-3
  `}

  a {
    font-weight: bold;
    color: black;
  }
`

function VideoWithText({
  title,
  description,
  video,
  footNote,
  invertColumns = false,
  useBackground = false,
  className,
  poster,
}) {
  const [videoPlaying, setVideoPlaying] = useState(null)

  const VideoColumn = () => {
    return (
      <div className="w-full lg:w-1/2 order-1 py-2 lg:py-0">
        {videoPlaying ? (
          <VideoModal
            title={videoPlaying.title}
            description={videoPlaying.description}
            video={videoPlaying.video}
            footer={videoPlaying.footer}
            onClose={() => {
              setVideoPlaying(null)
            }}
          />
        ) : null}
        <ImageContainer className="relative leading-zero">
          <Poster src={poster} />
          <PlayIcon
            onClick={() => {
              setVideoPlaying({
                title: title,
                video: video,
                description: description,
                footer: footNote,
              })
            }}
          >
            <img src={PlayIconImage} />
          </PlayIcon>
        </ImageContainer>
      </div>
    )
  }

  const TextColumn = () => {
    return (
      <div className="w-full lg:w-1/2 font-sans">
        <div
          className={[
            invertColumns ? 'lg:pl-4' : 'lg:pr-4',
            'lg:px-0 lg:py-0 flex flex-col-reverse lg:flex-col',
          ].join(' ')}
        >
          <ContainerDescription
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <h3
            className={classnames(
              'lg:font-black',
              'font-bold',
              'lg-pt-4',
              'lg:leading-relaxed',
              'leading-tight',
              'text-base',
              'px-0',
              'py-1',
              'lg:py-0'
            )}
          >
            {footNote}
          </h3>
        </div>
      </div>
    )
  }

  const ContainerComponent = styled.div`
    ${tw`
    -mx-6
    xl:-mx-0
    
  `}
  `

  const Inner = styled.div`
    ${tw`
    lg:px-0
    max-w-2xl
  `}
    margin: 0 auto;
  `

  return (
    <ContainerComponent>
      <div
        className={`${[
          useBackground ? 'bg-light-grey-2 ' : null,
          'lg:pt-6 lg:pb-7 px-3 ',
        ].join(' ')} ${className}`}
      >
        <Inner>
          <h2
            className={classnames(
              'font-bold',
              'text-2xl',
              'lg:leading-normal',
              'text-charcoal',
              'lg:pb-3',
              'font-sans',
              'lg:pt-0',
              'pt-4'
            )}
          >
            {title}
          </h2>
          {invertColumns ? (
            <div className="flex flex-col lg:flex-row">
              <VideoColumn />
              <TextColumn />
            </div>
          ) : (
            <div className="flex flex-col-reverse lg:flex-row">
              <TextColumn />
              <VideoColumn />
            </div>
          )}
        </Inner>
      </div>
    </ContainerComponent>
  )
}

export default VideoWithText
